<template>
  <a-spin :spinning="spinning">
    <section class="head" :class="{ hideBorder: evalType > 0 }">
      <div class="content">
        <h1>{{ dataInfo.questionnaireName }}</h1>
        <p>{{ dataInfo.intro }}</p>
      </div>
    </section>
    <section class="questions" :class="{ hideBorder: evalType > 0 }">
      <div class="content">
        <div :class="{ disabled: disabled }">
          <a-form layout="vertical" ref="quesFormRef" :model="formData">
            <template v-for="(item, index) in quesData" :key="index">
              <a-form-item
                v-if="item.questionType == 1"
                :label="index + 1 + '.' + item.title"
                :name="item.questionId"
                :rules="{
                  required: item.settingObj.required,
                  type: 'number',
                  message: $t('quesition.please_select'),
                  // 请选择
                  trigger: 'change',
                }"
              >
                <div
                  class="tit-media-wrap"
                  v-if="item.titimgs && item.titimgs.length"
                >
                  <a-image
                    v-if="getResourceType(item.titimgs[0]) == 'image'"
                    :src="item.titimgs[0]"
                  />
                  <video
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                    :src="item.titimgs[0]"
                    controls
                  />
                  <audio
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                    :src="item.titimgs[0]"
                    controls
                  />
                </div>
                <a-radio-group
                  v-model:value="formData[item.questionId]"
                  @change="radioChange($event, item.scoreList)"
                >
                  <div
                    :class="{
                      'col-1': item.settingObj.layoutScope == 1,
                      'col-2': item.settingObj.layoutScope == 2,
                      'col-3': item.settingObj.layoutScope == 3,
                    }"
                    v-for="(opt, optIndex) in item.scoreList"
                    :key="optIndex"
                  >
                    <a-radio class="opt-radio" :value="optIndex">
                      {{ opt.option }}
                      <a-form-item
                        class="supplement-form-item"
                        v-if="
                          item.settingObj.hasSupplement &&
                          optIndex == item.scoreList.length - 1 &&
                          formData[item.questionId] == item.scoreList.length - 1
                        "
                        :name="'s_' + item.questionId"
                        :rules="[
                          {
                            required: item.settingObj.supplementRequired,
                            type:
                              item.remarkRule == validatorNull ? 'number' : '',
                            message: $t('quesition.please_enter'),
                            // 请输入
                            trigger: 'change',
                          },
                          {
                            validator: item.remarkRule,
                          },
                        ]"
                      >
                        <a-input-number
                          v-if="item.settingObj.supplementRule == 2"
                          :readonly="progress == 100"
                          class="supplement-form-opt"
                          :placeholder="$t('quesition.please_enter')"
                          size="small"
                          v-model:value="formData['s_' + item.questionId]"
                          @blur="inputBlur($event, opt)"
                          :maxLength="item.settingObj.supplementMax || 80"
                        />
                        <!-- 请输入 -->
                        <a-input
                          v-else
                          :readonly="progress == 100"
                          class="supplement-form-opt"
                          :placeholder="$t('quesition.please_enter')"
                          size="small"
                          v-model:value="formData['s_' + item.questionId]"
                          @blur="inputBlur($event, opt)"
                          :maxLength="item.settingObj.supplementMax || 80"
                        />
                        <!-- 请输入 -->
                      </a-form-item>
                    </a-radio>
                    <div class="opt-media-wrap" v-if="opt.media">
                      <a-image
                        v-if="getResourceType(opt.media) == 'image'"
                        :src="opt.media"
                      />
                      <video
                        controlslist="nodownload"
                        v-else-if="getResourceType(opt.media) == 'video'"
                        :src="opt.media"
                        controls
                      />
                      <audio
                        controlslist="nodownload"
                        v-else-if="getResourceType(opt.media) == 'audio'"
                        :src="opt.media"
                        controls
                      />
                    </div>
                  </div>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                v-if="item.questionType == 2"
                :label="index + 1 + '.' + item.title"
                :name="item.questionId"
                :rules="[
                  {
                    required: item.settingObj.required,
                    type: 'array',
                    message: $t('quesition.please_select'),
                    // 请选择
                    trigger: 'change',
                  },
                  {
                    type: 'array',
                    min: item.settingObj.required
                      ? item.settingObj.min || 0
                      : 0,
                    message: $t('quesition.select_at_least_options', [
                      item.settingObj.min || 0,
                    ]),
                    // 最少选择 个选项
                    trigger: 'change',
                  },
                  {
                    type: 'array',
                    max: item.settingObj.max || 10000,
                    message: $t('quesition.select_up_to_options', [
                      item.settingObj.max || 10000,
                    ]),
                    // 最多选择 个选项
                    trigger: 'change',
                  },
                ]"
              >
                <div
                  class="tit-media-wrap"
                  v-if="item.titimgs && item.titimgs.length"
                >
                  <a-image
                    v-if="getResourceType(item.titimgs[0]) == 'image'"
                    :src="item.titimgs[0]"
                  />
                  <video
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                    :src="item.titimgs[0]"
                    controls
                  />
                  <audio
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                    :src="item.titimgs[0]"
                    controls
                  />
                </div>
                <a-checkbox-group
                  v-model:value="formData[item.questionId]"
                  @change="checkboxChange($event, item.scoreList)"
                >
                  <div
                    :class="{
                      'col-1': item.settingObj.layoutScope == 1,
                      'col-2': item.settingObj.layoutScope == 2,
                      'col-3': item.settingObj.layoutScope == 3,
                    }"
                    v-for="(opt, optIndex) in item.scoreList"
                    :key="optIndex"
                  >
                    <a-checkbox class="opt-checkbox" :value="optIndex">
                      {{ opt.option }}
                      <a-form-item
                        class="supplement-form-item"
                        v-if="
                          item.settingObj.hasSupplement &&
                          optIndex == item.scoreList.length - 1 &&
                          formData[item.questionId].includes(
                            item.scoreList.length - 1
                          )
                        "
                        :name="'s_' + item.questionId"
                        :rules="[
                          {
                            required: item.settingObj.supplementRequired,
                            type:
                              item.remarkRule == validatorNull ? 'number' : '',
                            message: $t('quesition.please_enter'),
                            // 请输入
                            trigger: 'change',
                          },
                          {
                            validator: item.remarkRule,
                          },
                        ]"
                      >
                        <a-input-number
                          v-if="item.settingObj.supplementRule == 2"
                          :readonly="progress == 100"
                          class="supplement-form-opt"
                          :placeholder="$t('quesition.please_enter')"
                          size="small"
                          v-model:value="formData['s_' + item.questionId]"
                          @blur="inputBlur($event, opt)"
                          :maxLength="item.settingObj.supplementMax || 80"
                        />
                        <!-- 请输入 -->
                        <a-input
                          v-else
                          :readonly="progress == 100"
                          class="supplement-form-opt"
                          :placeholder="$t('quesition.please_enter')"
                          size="small"
                          v-model:value="formData['s_' + item.questionId]"
                          @blur="inputBlur($event, opt)"
                          :maxLength="item.settingObj.supplementMax || 80"
                        />
                        <!-- 请输入 -->
                      </a-form-item>
                    </a-checkbox>
                    <div class="opt-media-wrap" v-if="opt.media">
                      <a-image
                        v-if="getResourceType(opt.media) == 'image'"
                        :src="opt.media"
                      />
                      <video
                        controlslist="nodownload"
                        v-else-if="getResourceType(opt.media) == 'video'"
                        :src="opt.media"
                        controls
                      />
                      <audio
                        controlslist="nodownload"
                        v-else-if="getResourceType(opt.media) == 'audio'"
                        :src="opt.media"
                        controls
                      />
                    </div>
                  </div>
                </a-checkbox-group>
              </a-form-item>
              <a-form-item
                v-if="item.questionType == 9"
                :label="index + 1 + '.' + item.title"
                :name="item.questionId"
                :rules="{
                  required: item.settingObj.required,
                  type: 'number',
                  message: $t('quesition.please_select'),
                  // 请选择
                  trigger: 'change',
                }"
              >
                <div
                  class="tit-media-wrap"
                  v-if="item.titimgs && item.titimgs.length"
                >
                  <a-image
                    v-if="getResourceType(item.titimgs[0]) == 'image'"
                    :src="item.titimgs[0]"
                  />
                  <video
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                    :src="item.titimgs[0]"
                    controls
                  />
                  <audio
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                    :src="item.titimgs[0]"
                    controls
                  />
                </div>
                <a-radio-group
                  v-model:value="formData[item.questionId]"
                  @change="radioChange($event, item.scoreList)"
                >
                  <div class="pic-opt-wrap">
                    <div
                      class="pic-radio"
                      v-for="(opt, optIndex) in item.scoreList"
                      :key="optIndex"
                    >
                      <div class="pic">
                        <a-image :src="opt.media" />
                      </div>
                      <a-radio class="opt-radio" :value="optIndex">
                        <span class="opt-radio-text">{{ opt.option }}</span>
                      </a-radio>
                      <div v-if="item.remarks[optIndex]">
                        <a-popover :title="opt.option" placement="bottom">
                          <template #content>
                            <div style="max-width: 400px">
                              {{ item.remarks[optIndex] }}
                            </div>
                          </template>
                          <div class="opt-dec">
                            【{{ $t("quesition.view_option_desc") }}】
                          </div>
                          <!-- 【查看选项说明】 -->
                        </a-popover>
                      </div>
                    </div>
                  </div>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                v-if="item.questionType == 10"
                :label="index + 1 + '.' + item.title"
                :name="item.questionId"
                :rules="[
                  {
                    required: item.settingObj.required,
                    type: 'array',
                    message: $t('quesition.please_select_answer'),
                    // 请选择答案
                    trigger: 'change',
                  },
                  {
                    type: 'array',
                    min: formData[item.questionId].length
                      ? item.settingObj.min || 0
                      : 0,
                    message: $t('quesition.select_at_least_options', [
                      item.settingObj.min || 0,
                    ]),
                    // 最少选择 个选项
                    trigger: 'change',
                  },
                  {
                    type: 'array',
                    max: item.settingObj.max || 10000,
                    message: $t('quesition.select_up_to_options', [
                      item.settingObj.max || 10000,
                    ]),
                    // 最多选择 个选项
                    trigger: 'change',
                  },
                ]"
              >
                <div
                  class="tit-media-wrap"
                  v-if="item.titimgs && item.titimgs.length"
                >
                  <a-image
                    v-if="getResourceType(item.titimgs[0]) == 'image'"
                    :src="item.titimgs[0]"
                  />
                  <video
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                    :src="item.titimgs[0]"
                    controls
                  />
                  <audio
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                    :src="item.titimgs[0]"
                    controls
                  />
                </div>
                <a-checkbox-group
                  v-model:value="formData[item.questionId]"
                  @change="checkboxChange($event, item.scoreList)"
                >
                  <div class="pic-opt-wrap">
                    <div
                      class="pic-radio"
                      v-for="(opt, optIndex) in item.scoreList"
                      :key="optIndex"
                    >
                      <div class="pic">
                        <a-image :src="opt.media" />
                      </div>
                      <a-checkbox class="opt-checkbox" :value="optIndex">
                        <span class="opt-checkbox-text">{{ opt.option }}</span>
                      </a-checkbox>
                      <div v-if="item.remarks[optIndex]">
                        <a-popover
                          :title="'选项' + (optIndex + 1)"
                          placement="bottom"
                        >
                          <template #content>
                            <div style="max-width: 400px">
                              {{ item.remarks[optIndex] }}
                            </div>
                          </template>
                          <div class="opt-dec">
                            【{{ $t("quesition.view_option_desc") }}】
                          </div>
                          <!-- 【查看选项说明】 -->
                        </a-popover>
                      </div>
                    </div>
                  </div>
                </a-checkbox-group>
              </a-form-item>
              <a-form-item
                v-if="item.questionType == 5"
                class="area-input"
                :label="index + 1 + '.' + item.title"
                :name="item.questionId"
                :rules="[
                  {
                    required: item.settingObj.required,
                    type: item.remarkRule == validatorNull ? 'number' : '',
                    message: $t('quesition.please_enter_content'),
                    // 请输入内容
                    trigger: 'change',
                  },
                  {
                    min:
                      item.remarkRule == validatorNull
                        ? minNum(item.settingObj.min)
                        : item.settingObj.min || 0,
                    type: item.remarkRule == validatorNull ? 'number' : '',
                    message:
                      item.remarkRule == validatorNull
                        ? $t('quesition.cannot_be_less', [
                            minNum(item.settingObj.min),
                          ])
                        : $t('quesition.enter_at_least', [
                            item.settingObj.min || 0,
                          ]),
                    // 不能小于 最少输入
                    trigger: 'change',
                  },
                  {
                    max:
                      item.remarkRule == validatorNull
                        ? maxNum(item.settingObj.max)
                        : item.settingObj.max || 999999999,
                    type: item.remarkRule == validatorNull ? 'number' : '',
                    message:
                      item.remarkRule == validatorNull
                        ? $t('quesition.cannot_be_greater', [
                            minNum(item.settingObj.max),
                          ])
                        : $t('quesition.enter_up_to', [
                            item.settingObj.max || 0,
                          ]),
                    // 不能大于 最多输入
                    trigger: 'change',
                  },
                  {
                    validator: item.remarkRule,
                  },
                ]"
              >
                <div
                  class="tit-media-wrap"
                  v-if="item.titimgs && item.titimgs.length"
                >
                  <a-image
                    v-if="getResourceType(item.titimgs[0]) == 'image'"
                    :src="item.titimgs[0]"
                  />
                  <video
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                    :src="item.titimgs[0]"
                    controls
                  />
                  <audio
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                    :src="item.titimgs[0]"
                    controls
                  />
                </div>
                <a-input-number
                  v-if="item.settingObj.contentScope == 2"
                  :readonly="progress == 100"
                  style="background-color: #f5f6f7; width: 100%"
                  v-model:value="formData[item.questionId]"
                  :placeholder="$t('quesition.please_enter_content')"
                  :maxLength="item.settingObj.supplementMax || 10000"
                  @blur="inputBlur($event, item.scoreList[0])"
                />
                <!-- 请输入内容 -->
                <a-textarea
                  v-else
                  :readonly="progress == 100"
                  auto-size
                  style="background-color: #f5f6f7"
                  v-model:value="formData[item.questionId]"
                  :placeholder="$t('quesition.please_enter_content')"
                  :maxLength="item.settingObj.supplementMax || 10000"
                  @blur="inputBlur($event, item.scoreList[0])"
                />
                <!-- 请输入内容 -->
              </a-form-item>
              <a-form-item
                v-if="item.questionType == 6"
                :label="index + 1 + '.' + item.title"
                :name="item.questionId"
                :rules="{
                  required: item.settingObj.required,
                  type: 'number',
                  message: $t('quesition.please_rate'),
                  // 请打分
                  trigger: 'change',
                }"
              >
                <div
                  class="tit-media-wrap"
                  v-if="item.titimgs && item.titimgs.length"
                >
                  <a-image
                    v-if="getResourceType(item.titimgs[0]) == 'image'"
                    :src="item.titimgs[0]"
                  />
                  <video
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                    :src="item.titimgs[0]"
                    controls
                  />
                  <audio
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                    :src="item.titimgs[0]"
                    controls
                  />
                </div>
                <a-rate
                  v-model:value="formData[item.questionId]"
                  :count="item.scoreList.length"
                  @change="
                    rateChange($event, item.scoreList);
                    item.scoreList[formData[item.questionId] - 1].remark2 = '';
                    formData['s_' + item.questionId] = '';
                    item.showRemark2 = false;
                  "
                >
                  <template #character>
                    <StarFilled
                      :style="{ fontSize: '32px' }"
                      v-if="item.settingObj.starType == 1"
                    />
                    <HeartFilled
                      :style="{ fontSize: '32px' }"
                      v-else-if="item.settingObj.starType == 2"
                    />
                    <SmileFilled
                      :style="{ fontSize: '32px' }"
                      v-else-if="item.settingObj.starType == 3"
                    />
                    <LikeFilled
                      :style="{ fontSize: '32px' }"
                      v-else-if="item.settingObj.starType == 4"
                    />
                  </template>
                </a-rate>
                <div
                  class="star-dec"
                  v-if="item.scoreList[formData[item.questionId] - 1]"
                >
                  {{ item.scoreList[formData[item.questionId] - 1].option }}
                </div>
                <div class="star-labs">
                  <template v-if="item.labels[formData[item.questionId] - 1]">
                    <span
                      class="lab"
                      :class="{
                        active: item.scoreList[
                          formData[item.questionId] - 1
                        ].remark
                          .split(',')
                          .includes(lab),
                      }"
                      v-for="(lab, labIndex) in item.labels[
                        formData[item.questionId] - 1
                      ].split(',')"
                      :key="labIndex"
                      @click="
                        rateLabChange(
                          lab,
                          item.scoreList[formData[item.questionId] - 1]
                        )
                      "
                      >{{ lab }}</span
                    >
                  </template>
                  <span
                    class="lab"
                    :class="{ active: item.showRemark2 }"
                    v-if="item.settingObj.hasSupplement"
                    @click="
                      rateShowInput(
                        item,
                        item.scoreList[formData[item.questionId] - 1]
                      );
                      formData['s_' + item.questionId] = '';
                    "
                  >
                    <FormOutlined
                      :style="{
                        fontSize: '16px',
                        marginRight: '5px',
                        verticalAlign: 'text-bottom',
                      }"
                    />{{ $t("quesition.write_evaluate") }}
                    <!-- 写评价 -->
                  </span>
                  <a-form-item
                    v-if="item.showRemark2"
                    :name="'s_' + item.questionId"
                    :rules="[
                      {
                        min:
                          item.remarkRule == validatorNull
                            ? minNum(item.settingObj.min)
                            : item.settingObj.min || 0,
                        type: item.remarkRule == validatorNull ? 'number' : '',
                        message:
                          item.remarkRule == validatorNull
                            ? $t('quesition.cannot_be_less', [
                                minNum(item.settingObj.min),
                              ])
                            : $t('quesition.enter_at_least', [
                                item.settingObj.min || 0,
                              ]),
                        // 不能小于 最少输入
                        trigger: 'change',
                      },
                      {
                        max:
                          item.remarkRule == validatorNull
                            ? maxNum(item.settingObj.max)
                            : item.settingObj.max || 80,
                        type: item.remarkRule == validatorNull ? 'number' : '',
                        message:
                          item.remarkRule == validatorNull
                            ? $t('quesition.cannot_be_greater', [
                                minNum(item.settingObj.max),
                              ])
                            : $t('quesition.enter_up_to', [
                                item.settingObj.max || 0,
                              ]),
                        // 不能大于 最多输入
                        trigger: 'change',
                      },
                      {
                        validator: item.remarkRule,
                      },
                    ]"
                  >
                    <a-input-number
                      v-if="item.settingObj.contentScope == 2"
                      :readonly="progress == 100"
                      class="read-input"
                      style="background-color: #f5f6f7; width: 100%"
                      :placeholder="$t('quesition.please_enter_evaluate')"
                      v-model:value="formData['s_' + item.questionId]"
                      @blur="
                        rateInputBlur(
                          $event,
                          item.scoreList[formData[item.questionId] - 1]
                        )
                      "
                      :maxLength="item.settingObj.supplementMax || 80"
                    />
                    <!-- 请输入评价 -->
                    <a-textarea
                      v-else
                      :readonly="progress == 100"
                      auto-size
                      style="background-color: #f5f6f7"
                      :placeholder="$t('quesition.please_enter_evaluate')"
                      v-model:value="formData['s_' + item.questionId]"
                      @blur="
                        rateInputBlur(
                          $event,
                          item.scoreList[formData[item.questionId] - 1]
                        )
                      "
                      :maxLength="item.settingObj.supplementMax || 80"
                    />
                    <!-- 请输入评价 -->
                  </a-form-item>
                </div>
              </a-form-item>
              <a-form-item
                v-if="item.questionType == 7"
                :label="index + 1 + '.' + item.title"
                :name="item.questionId"
                :rules="{
                  required: item.settingObj.required,
                  type: 'number',
                  message: $t('quesition.please_rate'),
                  // 请打分
                  trigger: 'change',
                }"
              >
                <div
                  class="tit-media-wrap"
                  v-if="item.titimgs && item.titimgs.length"
                >
                  <a-image
                    v-if="getResourceType(item.titimgs[0]) == 'image'"
                    :src="item.titimgs[0]"
                  />
                  <video
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                    :src="item.titimgs[0]"
                    controls
                  />
                  <audio
                    controlslist="nodownload"
                    v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                    :src="item.titimgs[0]"
                    controls
                  />
                </div>
                <div class="measure" style="width: 500px">
                  <a-radio-group
                    v-if="item.settingObj.starType == 5"
                    v-model:value="formData[item.questionId]"
                    @change="radioChange($event, item.scoreList)"
                  >
                    <a-radio
                      class="opt-radio"
                      v-for="(opt, optIndex) in item.scoreList"
                      :key="optIndex"
                      :value="optIndex"
                    >
                      {{
                        computedScore(
                          item.settingObj.startValue,
                          item.settingObj.score,
                          optIndex
                        )
                      }}
                      <!-- {{ optIndex + item.settingObj.startValue }} -->
                    </a-radio>
                  </a-radio-group>
                  <a-slider
                    v-else-if="item.settingObj.starType == 6"
                    v-model:value="formData[item.questionId]"
                    :min="1"
                    :max="item.scoreList.length"
                    :tip-formatter="(val) => item.scores[val - 1]"
                    @change="sliderChange($event, item.scoreList)"
                  />
                  <a-rate
                    v-else
                    v-model:value="formData[item.questionId]"
                    :count="item.scoreList.length"
                    @change="rateChange($event, item.scoreList)"
                  >
                    <template #character>
                      <StarFilled
                        :style="{ fontSize: '32px' }"
                        v-if="item.settingObj.starType == 1"
                      />
                      <HeartFilled
                        :style="{ fontSize: '32px' }"
                        v-else-if="item.settingObj.starType == 2"
                      />
                      <SmileFilled
                        :style="{ fontSize: '32px' }"
                        v-else-if="item.settingObj.starType == 3"
                      />
                      <LikeFilled
                        :style="{ fontSize: '32px' }"
                        v-else-if="item.settingObj.starType == 4"
                      />
                    </template>
                  </a-rate>
                  <div class="opt-strs">
                    <span
                      v-for="(str, strIndex) in item.scoreList"
                      :key="strIndex"
                      >{{ str.label }}</span
                    >
                  </div>
                  <span class="score" v-if="item.settingObj.starType == 6">{{
                    item.scores[formData[item.questionId] - 1]
                  }}</span>
                </div>
              </a-form-item>
            </template>
          </a-form>
        </div>
        <div class="footer-btn">
          <a-space>
            <a-button shape="round" size="large" @click="back">{{
              $t("go_back")
            }}</a-button>
            <!-- 返回 -->
            <a-button
              v-if="!disabled"
              shape="round"
              size="large"
              type="primary"
              @click="submit"
              >{{ $t("CM_Submit") }}</a-button
            >
            <!-- 提交 -->
          </a-space>
        </div>
      </div>
    </section>
  </a-spin>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, getCurrentInstance, computed,createVNode} from "vue";
import { useRouter, useRoute } from "vue-router";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import {
  validatorMobile,
  validatorEmail,
  validatorNull,
} from "@/utils/formRules";
import {
  questionnaireDetail,
  questionnaireSubmit,
  questionnaireResult,
  courseQuestionnaireSubmit,
  courseQuestionnaireResult,
} from "@/api/questionnaire";
import { getTaskDetail } from "@/api/project";
import { trainQuestionnaireSubmit } from "@/api/train";
import {
  trainMembersQuestionnaire,
  trainMembersQuestionnaireResult,
  trainMembersQuestionnaireSubmit,
} from "@/api/teacher";
import { plusDetail } from "@/api/course";
import { useStore } from "vuex";
export default {
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const taskId = parseInt(route.query.id || 0);
    const detailId = parseInt(route.query.did || 0);
    const questionnaireId = parseInt(route.query.questionnaireId || 0);
    const userId = parseInt(route.query.userId || 0);
    const evalType = 4;
    const taskaskId = parseInt(route.query.taskaskId || 0);
    const disabled = route.query.disabled ? true : false;
    const spinning = ref(true);

    const dataInfo = ref({});
    const quesData = ref([]);
    const formData = ref({});

    const init = () => {
      if (taskaskId) {
        getResultData();
      } else {
        getDetail();
      }
    };
    const getDetail = async () => {
      const res = await questionnaireDetail(questionnaireId);
      if (res.ret === 0) {
        dataInfo.value = res.data;
        quesData.value = res.data.questions || [];
        handleData();
      } else {
        proxy.$message.error(res.msg);
      }
    };
    const handleData = () => {
      quesData.value.map((item) => {
        item.settingObj = JSON.parse(item.setting);
        item.scoreList = [];
        item.options = item.options || [];
        item.options.forEach((opt, i) => {
          item.scoreList.push({
            option: opt,
            score: 0,
            remark: item.remarks[i],
            media: item.opimgs[i],
            label: item.labels[i],
          });
        });
        if (item.questionType == 1 || item.questionType == 2) {
          if (item.questionType == 2) {
            formData.value[item.questionId] = [];
          } else {
            formData.value[item.questionId] = "";
          }
          if (item.settingObj.hasSupplement) {
            switch (item.settingObj.supplementRule) {
              case 1:
                item.remarkRule = "";
                break;
              case 2:
                item.remarkRule = validatorNull;
                break;
              case 3:
                item.remarkRule = validatorEmail;
                break;
              case 4:
                item.remarkRule = validatorMobile;
                break;
            }
            formData.value["s_" + item.questionId] = "";
          }
        } else if (item.questionType == 5) {
          formData.value[item.questionId] = "";
          item.scoreList = [
            {
              option: "",
              score: 0,
              remark: "",
              media: "",
              label: "",
            },
          ];
          switch (item.settingObj.contentScope) {
            case 1:
              item.remarkRule = "";
              break;
            case 2:
              item.remarkRule = validatorNull;
              break;
            case 3:
              item.remarkRule = validatorEmail;
              break;
            case 4:
              item.remarkRule = validatorMobile;
              break;
          }
        } else if (item.questionType == 10) {
          formData.value[item.questionId] = [];
        } else if (item.questionType == 6) {
          formData.value[item.questionId] = "";
          switch (item.settingObj.contentScope) {
            case 1:
              item.remarkRule = "";
              break;
            case 2:
              item.remarkRule = validatorNull;
              break;
            case 3:
              item.remarkRule = validatorEmail;
              break;
            case 4:
              item.remarkRule = validatorMobile;
              break;
          }
        } else {
          formData.value[item.questionId] = "";
        }
      });
      spinning.value = false;
    };
    const getResultData = () => {
      let params = {
        taskId,
        detailId,
        questionnaireId,
        evalType,
        taskaskId,
      };
      questionnaireResult(params).then((res) => {
        dataInfo.value = res.data;
        quesData.value = res.data.questions || [];
        quesData.value.map((item) => {
          item.settingObj = JSON.parse(item.setting);
          item.scoreList = [];
          item.options = item.options || [];
          item.options.forEach((opt, i) => {
            item.scoreList.push({
              option: opt,
              score: item.answers[i],
              remark: item.remarks[i],
              media: item.opimgs[i],
              label: item.labels[i],
              checked: item.answers[i] ? true : false,
            });
          });
          if (item.questionType == 1 || item.questionType == 2) {
            if (item.settingObj.hasSupplement) {
              switch (item.settingObj.supplementRule) {
                case 1:
                  item.remarkRule = "";
                  break;
                case 2:
                  item.remarkRule = validatorNull;
                  break;
                case 3:
                  item.remarkRule = validatorEmail;
                  break;
                case 4:
                  item.remarkRule = validatorMobile;
                  break;
              }
              formData.value["s_" + item.questionId] = "";
            }
            if (item.questionType == 2) {
              formData.value[item.questionId] = [];
              item.answers.forEach((answer, answerIndex) => {
                if (answer == 1) {
                  formData.value[item.questionId].push(answerIndex);
                }
              });
              formData.value["s_" + item.questionId] =
                item.ps[item.ps.length - 1];
            } else {
              formData.value[item.questionId] = "";
              item.answers.forEach((answer, answerIndex) => {
                if (answer == 1) {
                  formData.value[item.questionId] = answerIndex;
                }
              });
              formData.value["s_" + item.questionId] =
                item.ps[item.ps.length - 1];
            }
          } else if (item.questionType == 5) {
            formData.value[item.questionId] = "";
            item.scoreList = [
              {
                option: "",
                score: 0,
                remark: "",
                media: "",
                label: "",
              },
            ];
            switch (item.settingObj.contentScope) {
              case 1:
                item.remarkRule = "";
                break;
              case 2:
                item.remarkRule = validatorNull;
                break;
              case 3:
                item.remarkRule = validatorEmail;
                break;
              case 4:
                item.remarkRule = validatorMobile;
                break;
            }
            formData.value[item.questionId] = item.ps[0];
          } else if (item.questionType == 9) {
            formData.value[item.questionId] = "";
            item.answers.forEach((answer, answerIndex) => {
              if (answer == 1) {
                formData.value[item.questionId] = answerIndex;
              }
            });
          } else if (item.questionType == 10) {
            formData.value[item.questionId] = [];
            item.answers.forEach((answer, answerIndex) => {
              if (answer == 1) {
                formData.value[item.questionId].push(answerIndex);
              }
            });
          } else if (item.questionType == 6) {
            formData.value[item.questionId] = "";
            switch (item.settingObj.contentScope) {
              case 1:
                item.remarkRule = "";
                break;
              case 2:
                item.remarkRule = validatorNull;
                break;
              case 3:
                item.remarkRule = validatorEmail;
                break;
              case 4:
                item.remarkRule = validatorMobile;
                break;
            }
            item.answers.forEach((answer, answerIndex) => {
              if (answer == 1) {
                formData.value[item.questionId] = answerIndex + 1;
                item.scoreList[answerIndex].remark = item.ps[answerIndex];
                let labels = item.labels[answerIndex].split(",");
                let remarks = item.ps[answerIndex].split(",");
                let lastRemark = remarks[remarks.length - 1];
                if (!labels.includes(lastRemark) && lastRemark != "") {
                  item.showRemark2 = true;
                  formData.value["s_" + item.questionId] = lastRemark;
                }
              }
            });
          } else if (item.questionType == 7) {
            formData.value[item.questionId] = "";
            item.answers.forEach((answer, answerIndex) => {
              if (answer == 1) {
                formData.value[item.questionId] = answerIndex + 1;
                if (item.settingObj.starType == 5) {
                  formData.value[item.questionId] = answerIndex;
                }
              }
            });
          } else {
            formData.value[item.questionId] = "";
          }
        });
        spinning.value = false;
      });
    };
    init();

    const getResourceType = (url) => {
      if (!url || url == "") {
        return "";
      }
      const imgFormat = ["jpg", "png", "gif"];
      const videoFormat = ["mp4"];
      const audioFormat = ["mp3"];
      let u = url.split(".");
      let suffix = u[u.length - 1].toLowerCase();
      let type = "";
      if (imgFormat.indexOf(suffix) >= 0) {
        type = "image";
      } else if (videoFormat.indexOf(suffix) >= 0) {
        type = "video";
      } else if (audioFormat.indexOf(suffix) >= 0) {
        type = "audio";
      }
      return type;
    };

    const maxNum = (len) => {
      let str = "";
      for (let i = 0; i < len; i++) {
        str += "9";
      }
      return Number(str);
    };

    const minNum = (len) => {
      let str = "1";
      for (let i = 1; i < len; i++) {
        str += "0";
      }
      return Number(str);
    };

    const radioChange = (e, scoreList) => {
      let val = e.target.value;
      scoreList.forEach((item, index) => {
        item.score = index === val ? 1 : 0;
      });
    };

    const checkboxChange = (e, scoreList) => {
      scoreList.forEach((item) => {
        item.score = 0;
      });
      e.forEach((item) => {
        scoreList[item].score = 1;
      });
    };
    
    const inputBlur = (e, opt) => {
      if(e.target.value==""||e.target.value == undefined||e.target.value == null){
        opt.score = 0;
      }else{
        opt.score = 1;
      }
      opt.remark = e.target.value;
    };

    const rateChange = (e, scoreList) => {
      scoreList.forEach((item, index) => {
        item.remark = "";
        item.score = index + 1 === e ? 1 : 0;
      });
    };

    const rateLabChange = (lab, answer) => {
      let labs = answer.remark.length ? answer.remark.split(",") : [];
      let index = labs.indexOf(lab);
      if (index == -1) {
        labs.push(lab);
      } else {
        labs.splice(index, 1);
      }
      answer.remark = labs.join(",");
    };

    const rateShowInput = (item, answer) => {
      answer.remark2 = "";
      if (item.showRemark2) {
        item.showRemark2 = false;
      } else {
        item.showRemark2 = true;
      }
    };

    const rateInputBlur = (e, answer) => {
      answer.remark2 = e.target.value;
    };

    const sliderChange = (e, scoreList) => {
      scoreList.forEach((item, index) => {
        item.score = index === e - 1 ? 1 : 0;
      });
    };

    const formatter = (value) => {
      return `${value}%`;
    };

    const quesFormRef = ref(null);
    const submit = () => {
      //未填写
      let unCheck=0;
      quesFormRef.value
        .validate()
        .then(async () => {
          let submitData = [];
          let labelRequiredFlag = 0;
          quesData.value.map((item, index) => {
            if (item.questionType == 6) {
              let list = item.scoreList;
              for (let i = 0; i < list.length; i++) {
                if (list[i].score == 1 && list[i].remark2) {
                  let remarks = list[i].remark.length
                    ? list[i].remark.split(",")
                    : [];
                  remarks.push(list[i].remark2);
                  list[i].remark = remarks.join(",");
                }
                if (
                  item.settingObj.labelRequired &&
                  list[i].score == 1 &&
                  list[i].remark == ""
                ) {
                  proxy.$message.error(
                    $t("quesition.quesition_option_required", [index + 1])
                  );
                  // 第 题至少选择一个标签！
                  labelRequiredFlag++;
                  return false;
                }
              }
              if (labelRequiredFlag) return false;
            }
            submitData.push({
              questionID: item.questionId,
              questionType: item.questionType,
              scoreList: item.scoreList,
            });
            let isCheck = false;
            item.scoreList.forEach(temp => {
              if(temp.score>0||(temp.remark!=""&&item.questionType==5)){
                console.log(item);
                isCheck = true;
              }
            });
            if(!isCheck){
              console.log(item);
              unCheck++;
            }
          });
          if (labelRequiredFlag) return false;
          const ids = {
            taskId,
            detailId,
            questionnaireId,
            userId,
            evalType: 4,
          };
          if(unCheck>0){
            Modal.confirm({
              title: () => "还有问卷项未填写，确定要继续吗？",
              icon: () => createVNode(ExclamationCircleOutlined),
              onOk:(async () => {
                const res = await questionnaireSubmit(ids, { options: submitData });
                if (res.ret == 0) {
                  proxy.$message.success($t("LB_Teacher_SubSuccess"));
                  back();
                }
              })
            });
          }else{
            const res = await questionnaireSubmit(ids, { options: submitData });
            if (res.ret == 0) {
              proxy.$message.success($t("LB_Teacher_SubSuccess"));
              back();
            }
          }

        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const back = () => {
      if (disabled) {
        router.go(-1);
      } else {
        router.push({
          path: '/mine',
          query: {
            taskId: taskId,
            detailId: detailId,
            key: 'homework',
            keep: 1,
          }
        })
      }
    };

    const computedScore = (start, score, index) => {
      if (score == 100) {
        return start + (index + 1) * 10;
      } else {
        return start + index;
      }
    };

    return {
      validatorMobile,
      validatorEmail,
      validatorNull,
      spinning,
      dataInfo,
      quesData,
      formData,
      getResourceType,
      maxNum,
      minNum,
      quesFormRef,
      radioChange,
      checkboxChange,
      inputBlur,
      rateChange,
      rateLabChange,
      rateShowInput,
      rateInputBlur,
      sliderChange,
      formatter,
      submit,
      back,
      computedScore,
      disabled,
    };
  },
};
</script>
<style lang="less" scoped>
.beoverdue {
  padding: 40px 0 0;
  .overdue {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .eks {
      width: 44px;
      height: 44px;
      background-color: #fe6d68;
      .mixinFlex(center, center);
      border-radius: 50%;
      span {
        font-size: 20px;
        color: #fff;
        font-weight: 700;
      }
    }
    .names {
      margin-top: 17px;
      font-size: 20px;
      color: #333333;
    }
  }
}
.head {
  padding-top: 40px;
  .content {
    min-height: 115px;
    .mixinWrap(1000px);
    background-color: #fff;
    text-align: center;
    border-radius: 20px;
    border: 1px solid #f5f6f7;
    padding: 46px 0 36px;
    position: relative;
    .statistics {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 118px;
      height: 34px;
      border: 1px solid @color-theme;
      font-size: 16px;
      color: @color-theme;
      text-align: center;
      line-height: 34px;
      cursor: pointer;
    }
    h1 {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 10px;
      color: #333;
    }
    p {
      font-size: 16px;
      line-height: 1.4;
      margin: 0 36px 0;
      color: #666;
      text-align: left;
    }
  }
  &.hideBorder {
    padding-top: 0px;
    .content {
      border: none;
    }
  }
}
.questions {
  padding-bottom: 80px;
  .content {
    min-height: 220px;
    .mixinWrap(1000px);
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #f5f6f7;
    padding: 36px;
    .ant-form-item {
      margin-bottom: 16px;
    }
    ::v-deep(.ant-form-item-label) {
      padding-bottom: 16px;
      & > label {
        font-size: 16px;
        align-items: unset;
        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          padding-top: 6px;
        }
      }
    }
    .col-1 {
      margin-bottom: 10px;
    }
    .col-2 {
      width: 46%;
      margin-right: 4%;
      display: inline-block;
      vertical-align: text-top;
      margin-bottom: 10px;
    }
    .col-3 {
      width: 30%;
      margin-right: 3%;
      display: inline-block;
      vertical-align: text-top;
      margin-bottom: 10px;
    }
    .opt-radio {
      white-space: normal;
      line-height: 1.8;
      ::v-deep(.ant-radio) {
        vertical-align: text-top;
      }
    }
    .supplement-form-item {
      display: inline-block;
      margin-bottom: 0;
      ::v-deep(.ant-form-item-control-input) {
        min-height: 26px;
      }
      ::v-deep(.ant-input-number:hover .ant-input-number-handler-wrap) {
        opacity: 0;
        display: none;
      }
    }
    .supplement-form-opt {
      width: 180px;
      border-width: 0 !important;
      border-bottom-width: 1px !important;
      box-shadow: none !important;
      border-radius: 0;
    }
    .tit-media-wrap {
      width: 500px;
      padding-bottom: 16px;
      font-size: 0;
      audio,
      video {
        width: 100%;
        font-size: 0;
      }
      audio::-webkit-media-controls-enclosure {
        border-radius: 0;
      }
      ::v-deep(.ant-image) {
        width: 100%;
        max-height: 300px;
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
          width: auto;
          max-width: 100%;
          cursor: pointer;
        }
      }
    }
    .opt-media-wrap {
      width: 300px;
      overflow: hidden;
      audio,
      video {
        width: 100%;
        font-size: 0;
      }
      audio::-webkit-media-controls-enclosure {
        border-radius: 0;
      }
      ::v-deep(.ant-image) {
        width: 100%;
        max-height: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
          width: auto;
          max-width: 100%;
          cursor: pointer;
        }
      }
    }
    .pic-opt-wrap {
      .mixinFlex();
      flex-wrap: wrap;
      .pic-radio {
        .mixinFlex(flex-start; center; column);
        width: 173px;
        margin-right: 15px;
        margin-bottom: 10px;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        &:nth-child(5n) {
          margin-right: 0;
        }
        .pic {
          width: 100%;
          height: 200px;
          background-color: #f1f1f1;
          ::v-deep(.ant-image) {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              cursor: pointer;
            }
          }
        }
        .opt-checkbox,
        .opt-radio {
          width: 100%;
          margin: 0;
          padding: 12px 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          &-text {
            word-break: break-all;
            white-space: normal;
          }
        }
        .opt-dec {
          text-align: center;
          margin-bottom: 12px;
          font-size: 12px;
          color: @color-theme;
          cursor: default;
        }
      }
    }
    ::v-deep(.ant-rate-star:not(:last-child)) {
      margin-right: 16px;
    }
    .star-dec {
      color: #999;
      font-size: 14px;
      margin-top: 20px;
    }
    .star-labs {
      margin-top: 20px;
      .lab {
        display: inline-block;
        padding: 12px 22px;
        font-size: 12px;
        line-height: 20px;
        background-color: #fff;
        color: #666;
        box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid #eeeeee;
        margin: 0 26px 20px 0;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
        }
        &.active {
          box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid @color-theme;
          color: @color-theme;
        }
      }
    }
    .measure {
      position: relative;
      .score {
        position: absolute;
        top: -5px;
        right: -32px;
        font-size: 16px;
      }
      .opt-strs {
        .mixinFlex(space-between);
        font-size: 14px;
        color: #333;
        line-height: 20px;
        padding: 10px 0;
      }
      ::v-deep(.ant-rate) {
        .mixinFlex(space-between);
        padding: 5px 0;
      }
      ::v-deep(.ant-radio-group) {
        .mixinFlex(space-between);
        padding: 5px 0;
        span.ant-radio + * {
          padding-right: 0;
        }
      }
    }
    .btns {
      text-align: center;
      .btn {
        background-color: @color-theme;
        color: #fff;
        width: 172px;
        height: 48px;
        border-radius: 24px;
      }
    }
  }
  .disabled {
    pointer-events: none;
    cursor: default;
    input,
    textarea,
    .read-input,
    ::v-deep(.ant-image),
    audio,
    video,
    .supplement-form-item {
      pointer-events: auto;
    }
  }
  &.hideBorder {
    padding-bottom: 0px;
    .content {
      border: none;
    }
  }
}
.footer-btn {
  text-align: center;
}
</style>
